.how-it-works {
  @media (max-width: 2560px) {
    padding: 50px 0;
  }
  @media (max-width: 1440px) {
    // padding: 50px 5%;
  }

  .accordion {
    display: flex;
    align-items: center;
    // @media (max-width: 2560px) {
    //   height: 40vh;
    // }
    // @media (max-width: 1440px) {
    //   height: 65vh;
    // }
    @media (max-width: 991px) {
      height: auto;
      flex-direction: column;
    }

    .accordion-cell {
      display: inline-block;
      position: relative;
      // height: 65vh;
      width: 20vw;
      transition-property: width;
      transition-duration: 250ms;
      border: 1px solid #dbdbdb;
      border-radius: 1rem;
      background-color: #fff;
      height: 55vh;
      cursor: pointer;

      // @media (max-width: 2560px) {
      //   height: 25vh;
      // }
      @media only screen and (min-width: 2560px) {
        height: 45vh;
      }
      @media only screen and (max-width: 1440px) {
        height: 55vh;
      }
      @media (max-width: 1024px) {
        height: 78vh;
        // margin-bottom: 10px;
      }
      @media (max-width: 991px) {
        height: auto;
      }

      img {
        // box-shadow: 0 20px 24px -4px rgb(0 0 0 / 8%),
        //   0 8px 8px -4px rgb(0 0 0 / 3%);
        width: 460px;
        height: 372px;
        object-fit: contain;

        @media (max-width: 1024px) {
          width: 400px;
          height: 362px;
        }
        @media (max-width: 991px) {
          height: auto;
          padding: 0 20px;
        }
      }

      .cell-text {
        max-width: 28rem;
        padding-left: 8rem;
        margin-right: 55px;

        @media (max-width: 1024px) {
          padding-left: 30px;
        }
        @media (max-width: 991px) {
          max-width: 100%;
          margin-right: 0;
          padding: 20px 50px;
          text-align: center;
        }
        @media (max-width: 425px) {
          h1 {
            font-size: 20px;
          }
        }
      }

      &:nth-child(2) {
        margin: 0 10px;

        @media (max-width: 991px) {
          margin: 10px 0;
        }
      }

      .accordion-cell-content {
        height: 100%;
      }
      & > h4 {
        display: block;
        position: absolute;
        width: 4rem;
        margin: 0;
        padding: 0;
        left: -0rem;
        bottom: 2rem;
        font-size: 2em;
        line-height: 1em;
        text-align: left;
        color: #000;
        transform: rotate(-90deg);
        transition-property: left right transform;
        transition-duration: 250ms;

        @media (max-width: 991px) {
          width: auto;
          padding-top: 20px;
          padding-left: 20px;
          transform: none;
        }
      }
      & > .accordion-cell-content {
        display: block;
        width: 75vw;
        max-height: 80vh;
        margin: 0;
        padding: 4rem 0;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 250ms;
        overflow: hidden;

        @media (max-width: 2560px) {
          width: 100%;
        }
        @media (max-width: 1024px) {
          padding: 30px 0;
        }
        @media (max-width: 991px) {
          padding: 0;
        }
        // @media (max-width: 1440px) {
        //   width: 63vw;
        // }
        // @media (max-width: 1440px) {
        //   width: 75vw;
        // }

        & > p {
          display: block;
          margin: 0;
          padding: 0 0 0 0;
          font-size: 1.125em;
          line-height: 1.5em;
          letter-spacing: 1px;
        }
      }
    }

    .expanded {
      width: 75vw;

      @media (max-width: 2560px) {
        width: 100%;
      }
      @media (max-width: 991px) {
        padding-bottom: 25px;
      }
      // @media (max-width: 1440px) {
      //   width: 63vw;
      // }
      // @media (max-width: 1440px) {
      //   width: 75vw;
      // }

      & > {
        h4 {
          left: 1rem;
          bottom: 1rem;
          transform: rotate(0deg);

          @media (max-width: 991px) {
            transform: none;
            position: relative;
            left: unset;
            bottom: unset;
          }
        }
      }
      & > .accordion-cell-content {
        opacity: 1;
        transition-property: opacity;
        transition-duration: 250ms;
      }
    }

    .collapsed {
      width: 6.25vw;

      @media (max-width: 2560px) {
        width: 4.5vw;
      }
      @media (max-width: 1440px) {
        width: 6.25vw;
        font-size: 10px;
      }
      @media (max-width: 991px) {
        width: 100%;
        height: 70px;
      }
    }
  }

  &.ar {
    flex-direction: row-reverse;

    .cell-text {
      max-width: 28rem;
      padding-right: 8rem;
      margin-left: 55px;

      @media (max-width: 1024px) {
        padding-right: 30px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        margin-left: 0;
        padding: 20px 50px;
        text-align: center;
      }
      @media (max-width: 425px) {
        h1 {
          font-size: 20px;
        }
      }
    }

    .expanded {
      & > {
        h4 {
          right: 1rem;
          bottom: 1rem;
          transform: rotate(0deg);

          @media (max-width: 991px) {
            transform: none;
            position: relative;
            right: unset;
            bottom: unset;
          }
        }
      }
    }

    .accordion-cell {
      & > h4 {
        text-align: right;
        font-size: 16px;

        @media (max-width: 991px) {
          padding-right: 20px;
        }
      }
    }
  }
}
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #000;
  transition: color 0.2s;

  border-bottom: 1px solid #f6f9fa;
  padding-bottom: 10px;

  &:first-child,
  &:last-child {
    border-bottom: none;
  }

  a {
    margin: 0;
  }
}

.menu-item {
  text-decoration: none;
  margin-right: 10px !important;
  transition: color 0.2s;

  @media (max-width: 320px) {
    font-size: 12px;
  }

  &:hover {
    color: #fde4d8;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 25px;
  left: 102px;
  top: 34px;

  @media (max-width: 650px) {
    left: unset;
    right: 15px;
  }
}

.bm-burger-bars {
  background: #907fc1;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  width: 100% !important;
  .bm-cross-button {
    top: 33px !important;
  }
}

.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #000;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
